<template>
  <div class="mb-8">
    <section class="text-gray-700">
      <div class="container px-5 py-24 mx-auto">
        <div class="text-center mb-20">
          <h1
            class="
              sm:text-3xl
              text-2xl
              font-medium
              text-center
              title-font
              text-gray-900
              mb-4
            "
          >
            GDPR Policy
          </h1>
        </div>
      </div>
    </section>

    <div class="container leading-relaxed mx-auto">
      <h3 class="title-font text-bold-600 mb-4 text-2xl">
        Privacy Policy for AlphaSoftHub
      </h3>
      <p class="text-base">
        At AlphaSoftHub, accessible from http://alphasofthub.com/, one of our
        main priorities is the privacy of our visitors. This Privacy Policy
        document contains types of information that is collected and recorded by
        AlphaSoftHub and how we use it.
      </p>
      <p class="text-base">
        If you have additional questions or require more information about our
        Privacy Policy, do not hesitate to contact us.
      </p>

      <h3 class="title-font text-bold-600 mb-4 text-2xl">
        General Data Protection Regulation (GDPR)
      </h3>
      <p class="text-base">We are a Data Controller of your information.</p>
      <p class="text-base">
        AlphaSoftHub legal basis for collecting and using the personal
        information described in this Privacy Policy depends on the Personal
        Information we collect and the specific context in which we collect the
        information:
      </p>
      <li>AlphaSoftHub needs to perform a contract with you</li>
      <li>You have given AlphaSoftHub permission to do so</li>
      <li>
        Processing your personal information is in AlphaSoftHub legitimate
        interests
      </li>
      <li>AlphaSoftHub needs to comply with the law</li>
      <p class="text-base">
        AlphaSoftHub will retain your personal information only for as long as
        is necessary for the purposes set out in this Privacy Policy. We will
        retain and use your information to the extent necessary to comply with
        our legal obligations, resolve disputes, and enforce our policies.
      </p>
      <p class="text-base">
        If you are a resident of the European Economic Area (EEA), you have
        certain data protection rights. If you wish to be informed what Personal
        Information we hold about you and if you want it to be removed from our
        systems, please contact us.
      </p>
      <p class="text-base">
        In certain circumstances, you have the following data protection rights:
      </p>
      <li>
        The right to access, update or to delete the information we have on you.
      </li>
      <li>The right of rectification.</li>
      <li>The right to object.</li>
      <li>The right of restriction.</li>
      <li>The right to data portability</li>
      <li>The right to withdraw consent</li>

      <h3 class="title-font text-bold-600 mb-4 text-2xl">Log Files</h3>
      <p class="text-base">
        AlphaSoftHub follows a standard procedure of using log files. These
        files log visitors when they visit websites. All hosting companies do
        this and a part of hosting services’ analytics. The information
        collected by log files include internet protocol (IP) addresses, browser
        type, Internet Service Provider (ISP), date and time stamp,
        referring/exit pages, and possibly the number of clicks. These are not
        linked to any information that is personally identifiable. The purpose
        of the information is for analyzing trends, administering the site,
        tracking users’ movement on the website, and gathering demographic
        information.
      </p>

      <h3 class="title-font text-bold-600 mb-4 text-2xl">
        Cookies and Web Beacons
      </h3>
      <p class="text-base">
        Like any other website, AlphaSoftHub uses ‘cookies’. These cookies are
        used to store information including visitors’ preferences, and the pages
        on the website that the visitor accessed or visited. The information is
        used to optimize the users’ experience by customizing our web page
        content based on visitors’ browser type and/or other information.
      </p>
      <p class="text-base">
        For more general information on cookies, please read
        <a href="https://www.cookieconsent.com/what-are-cookies/"
          >“What Are Cookies”</a
        >.
      </p>

      <h3 class="title-font text-bold-600 mb-4 text-2xl">
        Google DoubleClick DART Cookie
      </h3>
      <p class="text-base">
        Google is one of a third-party vendor on our site. It also uses cookies,
        known as DART cookies, to serve ads to our site visitors based upon
        their visit to www.website.com and other sites on the internet. However,
        visitors may choose to decline the use of DART cookies by visiting the
        Google ad and content network Privacy Policy at the following URL –
        https://policies.google.com/technologies/ads
      </p>

      <h3 class="title-font text-bold-600 mb-4 text-2xl">
        Our Advertising Partners
      </h3>
      <p class="text-base">
        Some of advertisers on our site may use cookies and web beacons. Our
        advertising partners are listed below. Each of our advertising partners
        has their own Privacy Policy for their policies on user data. For easier
        access, we hyperlinked to their Privacy Policies below.
      </p>
      <li><a href="https://policies.google.com/technologies/ads">Google</a></li>

      <h3 class="title-font text-bold-600 mb-4 text-2xl">Privacy Policies</h3>
      <p class="text-base">
        You may consult this list to find the Privacy Policy for each of the
        advertising partners of AlphaSoftHub.
      </p>
      <p class="text-base">
        Third-party ad servers or ad networks uses technologies like cookies,
        JavaScript, or Web Beacons that are used in their respective
        advertisements and links that appear on AlphaSoftHub, which are sent
        directly to users’ browser. They automatically receive your IP address
        when this occurs. These technologies are used to measure the
        effectiveness of their advertising campaigns and/or to personalize the
        advertising content that you see on websites that you visit.
      </p>
      <p class="text-base">
        Note that AlphaSoftHub has no access to or control over these cookies
        that are used by third-party advertisers.
      </p>

      <h3 class="title-font text-bold-600 mb-4 text-2xl">
        Third Party Privacy Policies
      </h3>
      <p class="text-base">
        AlphaSoftHub’s Privacy Policy does not apply to other advertisers or
        websites. Thus, we are advising you to consult the respective Privacy
        Policies of these third-party ad servers for more detailed information.
        It may include their practices and instructions about how to opt-out of
        certain options.
      </p>
      <p class="text-base">
        You can choose to disable cookies through your individual browser
        options. To know more detailed information about cookie management with
        specific web browsers, it can be found at the browsers’ respective
        websites.
      </p>

      <h3 class="title-font text-bold-600 mb-4 text-2xl">
        Children’s Information
      </h3>
      <p class="text-base">
        Another part of our priority is adding protection for children while
        using the internet. We encourage parents and guardians to observe,
        participate in, and/or monitor and guide their online activity.
      </p>
      <p class="text-base">
        AlphaSoftHub does not knowingly collect any Personal Identifiable
        Information from children under the age of 13. If you think that your
        child provided this kind of information on our website, we strongly
        encourage you to contact us immediately and we will do our best efforts
        to promptly remove such information from our records.
      </p>

      <h3 class="title-font text-bold-600 mb-4 text-2xl">
        Online Privacy Policy Only
      </h3>
      <p class="text-base">
        Our Privacy Policy applies only to our online activities and is valid
        for visitors to our website with regards to the information that they
        shared and/or collect in AlphaSoftHub. This policy is not applicable to
        any information collected offline or via channels other than this
        website.
      </p>

      <h3 class="title-font text-bold-600 mb-4 text-2xl">Consent</h3>
      <p class="text-base">
        By using our website, you hereby consent to our Privacy Policy and agree
        to its terms.
      </p>
    </div>
  </div>
</template>